.review {
  width: 340px;
  margin: 0 auto;
  margin-top: 60px;
  background-color: white;
  padding: 10px;
  border-radius: 12px;
  h1 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 32px;
    text-align: center;
  }
  p {
    margin-bottom: 24px;
    text-align: center;
  }
}
