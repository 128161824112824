.drawer {
  background-color: #ffffff;
  position: absolute;
  height: 100vh;
  max-height: 100svh;
  top: 0px;
  right: 0;
  width: 260px;
  padding: 60px 20px 20px 20px;
  z-index: 100;
  box-shadow: 0px 0px 0px 10000px #77777777;
}
.content {
  display: flex;
  flex-direction: column;
}
.button {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 200;
}
