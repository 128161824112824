.Layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  margin: auto;
  padding-left: 8px;
  padding-right: 8px;
  max-width: 1280px;
}
.main {
  flex: 1;
}
