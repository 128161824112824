.authRoot {
  max-width: 400px;
  margin: 0 auto;
  margin-top: 20px;
  min-height: 450px;
  background-color: white;
  border-radius: 12px;
  border: 2px solid #2182c3;
  .header {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2182c3;
    border-radius: 10px 10px 0 0;
    color: white;
    font-weight: 600;
  }
  .body {
    padding: 12px;
    padding-bottom: 25px;
    .footer {
      margin-top: 25px;
      border-top: 1px solid #ebebeb;
      padding-top: 25px;
      font-size: 14px;
      text-align: center;
      .link {
        color: #2182c3;
      }
    }
  }
}

.form-items {
  margin-top: 12px;
  div.requirement {
    p {
      color: #ff0000;
      font-weight: 400;
    }
  }
  p {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 12px;
  }
}
.button-wapper {
  margin-top: 24px;
}
.resend {
  margin-top: 30px;
  border-top: 1px solid #ebebeb;
  padding-top: 30px;
}
.forgetpassword {
  text-align: right;
  margin-top: 24px;
  font-size: 14px;
  color: #2182c3;
}
