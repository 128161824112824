$color1: #fef6db;
$color2: #e3af00;

.button {
  font-size: 16px;
  font-weight: bold;
  padding: 8px 12px;
  border-radius: 4px;
  white-space: nowrap;
}
.true {
  @extend .button;
  background-color: $color2;
  color: white;
}
.false {
  @extend .button;
  background-color: $color1;
  color: $color2;
}
