@mixin button() {
  border-radius: 9999px;
  padding: 16px 32px;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  &:hover {
    filter: brightness(0.9);
  }
}
.fullWidth {
  width: 100%;
  height: 100%;
}
.buttonSolid {
  color: #fff;
  @include button();
  background: transparent linear-gradient(111deg, #2f9fd1 0%, #1163b5 100%) 0%
    0% no-repeat padding-box;
}
.buttonOutline {
  @include button();
  color: #2286c5;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: inset 0 0 0 1px #2286c5;
}
