.contact {
  min-height: 70vh;
  max-width: 1024px;
  margin: 0 auto;
  margin-top: 30px;
  border: 2px solid #2082c2;
  border-radius: 12px 12px 12px 12px;
  background-color: white;
  .header {
    text-align: center;
    background: #2182c3 0% 0% no-repeat padding-box;
    border-radius: 9px 9px 0 0;
    height: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border: none;
  }
  h1 {
    font-size: 38px;
    font-weight: 600;
  }
  h2 {
    font-size: 18px;
    font-weight: 500;
    margin-top: 10px;
  }
  .body {
    padding-top: 40px;
    padding-bottom: 40px;
    .content {
      width: 340px;
      margin: 0 auto;
      .bold {
        font-size: 20px;
        font-weight: 600;
      }
      .sm {
        font-size: 16px;
        span {
          color: red;
        }
      }
      .form-item {
        margin-top: 25px;
        p {
          margin-bottom: 15px;
        }
        textarea {
          width: 100%;
          padding: 12px 18px;
          border-radius: 5px;
          border: 1px solid #b1acac;
          font-size: 14px;
          height: 80px;
        }
        .radio-wapper {
          display: flex;
          flex-wrap: wrap;
        }

        .radio-wapper label {
          width: 50%;
        }

        .radio-wapper label input[type="radio"] {
          opacity: 0;
          appearance: none;
          position: absolute;
        }

        .radio-wapper .text {
          display: inline-block;
          position: relative;
          padding-left: 26px;
          line-height: 2.2;
        }

        .radio-wapper label .text::before {
          position: absolute;
          top: 8px;
          left: 0;
          width: 19px;
          height: 19px;
          border: 2px solid #b1acac;
          background: #fff;
          content: "";
          border-radius: 50%;
        }

        .radio-wapper label .text::after {
          position: absolute;
          top: 12.5px;
          left: 4.5px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #2182c3;
          content: "";
          opacity: 0;
        }

        .radio-wapper label input:checked + span::after {
          opacity: 1;
        }
        .radio-wapper label input:checked + span::before {
          border: 2px solid #2182c3;
        }
        //.checkbox {
        //  display: flex;
        //  flex-wrap: wrap;
        //}
        //
        //.checkbox label {
        //  width: 100%;
        //}
        //
        //.checkbox label {
        //  display: flex;
        //  align-items: center;
        //  width: 100%;
        //}
        //
        //.checkbox input[type="checkbox"] {
        //  position: absolute;
        //}
        //
        //.checkbox .text {
        //  display: inline-block;
        //  padding-left: 26px;
        //}

        .checkBoxWrapper {
          display: flex;
          align-items: center;
          margin: 5px 0;
        }

        .checkbox {
          padding-top: 3px;
          appearance: none;
          outline: none;
          width: 18px;
          height: 18px;
          position: relative;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
          box-sizing: border-box;
        }

        .checkbox::before {
          display: block;
          position: absolute;
          content: "";

          width: 18px;
          height: 18px;
          background: #fff;
          border: solid 1px #dddddd;
          border-radius: 4px;
        }

        .checkbox:checked::before {
          background-color: #1976d2;
          border: none;
        }

        .checkbox::after {
          padding-top: 8px;
          display: block;

          content: "";
          position: absolute;
          left: 7px;
          top: 5px;

          width: 4px;
          height: 2px;
          border-right: 2px solid #fff;
          border-bottom: 2px solid #fff;
          transform: rotate(45deg);
          opacity: 0;
        }

        .checkbox:checked::after {
          opacity: 1;
        }
      }
    }
  }
}
.statusTrue {
  text-align: center;
}

textarea:focus {
  outline: none;
}
