.Select {
  &::placeholder {
    color: #b1acac;
  }
  appearance: auto;
  width: 100%;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #b1acac;
}
