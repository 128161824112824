@use "./style/reset.scss";

:root {
  --primary: #2082c2;
  --secondary: #fef6db;
  --secondary-focus: #e3af00;
}
body {
  margin: 0;
  font-family: "Noto Sans JP", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("./image/bg.png");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
