@use "../../style/variables.scss" as variables;

.TalentList {
  @include variables.mq(sm) {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
  @include variables.mq(md) {
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
  }
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  grid-auto-rows: minmax(100px, auto);
  padding: 10px;
}
.refineList {
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  overflow-x: scroll;
  gap: 8px;
  margin-top: 20px;
  margin-bottom: 63px;
  scrollbar-width: none;
}

.refineList::-webkit-scrollbar {
  display: none;
}
