.Footer {
  padding-top: 20px;
  padding-bottom: 50px;
  margin-top: 40px;
}
.small {
  display: block;
  margin: auto;
  text-align: center;
  color: #999999;
  font-size: 14px;
}
