.mypage {
  max-width: 1024px;
  margin: 0 auto;
  background-color: white;
  border: 2px solid #2182c3;
  border-radius: 12px;
  margin-bottom: 50px;
  .head {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2182c3;
    border-radius: 10px 10px 0 0;
    color: white;
    font-size: 18px;
    font-weight: 600;
  }
  .body {
    padding: 20px;
    .form-items {
      margin-top: 24px;
      p {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 12px;
      }
    }
    Button {
      margin-bottom: 20px;
    }
    .button-wapper {
      margin-top: 12px;
      display: flex;
      justify-content: right;
    }
  }
}

.mypage-wapper {
  padding: 10px;
}
