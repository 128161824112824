.talnet-form-item {
  margin-top: 20px;
  p {
    margin-bottom: 10px;
    font-weight: 600;
  }
}
.age {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .age-form {
    width: 45%;
  }
}
.checkboxRoot {
  display: flex;
}
.checkboxWrapper {
  display: flex;
  align-items: center;
  margin: 5px 0;
  width: 100px;
}

.checkbox {
  padding-top: 3px;
  appearance: none;
  outline: none;
  width: 18px;
  height: 18px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  box-sizing: border-box;
}

.checkbox::before {
  display: block;
  position: absolute;
  content: "";

  width: 18px;
  height: 18px;
  background: #fff;
  border: solid 1px #dddddd;
  border-radius: 4px;
}

.checkbox:checked::before {
  background-color: #1976d2;
  border: none;
}

.checkbox::after {
  padding-top: 8px;
  display: block;

  content: "";
  position: absolute;
  left: 7px;
  top: 5px;

  width: 4px;
  height: 2px;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(45deg);
  opacity: 0;
}

.checkbox:checked::after {
  opacity: 1;
}
//.checkbox {
//  display: flex;
//  flex-wrap: wrap;
//}
//
//.checkbox label {
//  width: 80px;
//  display: flex;
//  align-items: center;
//}
//
//.checkbox input[type="checkbox"] {
//  position: absolute;
//}
//
//.checkbox .text {
//  display: inline-block;
//  padding-left: 26px;
//}

.sortButton {
  background-color: red;
  border-radius: 999px;
  padding: 16px 0;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  margin-top: 10px;
  background: transparent linear-gradient(111deg, #2f9fd1 0%, #1163b5 100%) 0%
    0% no-repeat padding-box;
  color: white;
  font-weight: 600;
  font-size: 14px;
}
