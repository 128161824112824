@use "../../style/variables" as variable;

.navBar {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 12px;
  padding: 30px 20px;
  @include variable.mq(md) {
    flex-direction: row;
    padding: 60px 80px;
  }
}
.rightButtons {
  gap: 12px;
  align-items: center;
  flex-direction: row;
  display: none;
  @include variable.mq(md) {
    display: flex;
  }
}
.navLeft {
  margin-right: auto;
}
.space {
  flex: 1;
}
/* 画面幅が小さくなったらドロワーのボタンが出るようにする*/
.drawer {
  display: block;

  @include variable.mq(md) {
    display: none;
  }
}
.DrawerContet {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.logo {
  width: 200px;
  height: auto;
}
