.modal-root {
  position: relative;
  margin: 0;
  padding: 0;
  .modal-background {
    background-color: rgba(0, 0, 0, 0.4);
    content: "";
    width: 100vw;
    min-height: 100%;
    z-index: 2;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    padding-top: 10px;
  }
  .modal-root {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .modal {
      background-color: white;
      padding: 33px 36px;
      width: 640px;
      background-color: white;
      box-sizing: border-box;
      border-radius: 5px;
      box-sizing: border-box;
      .modal-navbar {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #e5e5e5;
        padding-bottom: 22px;
        span {
          font-size: 16px;
          font-weight: 600;
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}
