@use "../../style/variables" as variable;

.Input {
  &::placeholder {
    color: #b1acac;
  }
  width: 100%;
  padding: 12px 18px;
  border-radius: 5px;
  border: 1px solid #b1acac;
  font-size: 16px;
  @include variable.mq(sm) {
    font-size: 14px;
  }
}
.Input:focus {
  outline: none;
}
