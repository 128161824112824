.age {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .age-form {
    flex: 1;
  }
}
.talnet-form-item {
  margin: 20px 0;
  p {
    margin-bottom: 10px;
  }
}
.buttons {
  display: flex;
  align-items: right;
  margin-top: 15px;
  flex-wrap: wrap;
}
