@use "../../style/variables.scss" as variable;

.TalentListText {
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  align-items: center;
  gap: 0px;
  flex-direction: column;
  @include variable.mq(md) {
    flex-direction: row;
    gap: 34px;
  }
  padding-left: 10px;
}
.talent {
  font-size: 42px;
  font-weight: 900;
}
.list {
  font-size: 20px;
  font-weight: 900;
}
