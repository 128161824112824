.RefimentButton {
  border: solid #707070 1px;
  display: inline-flex;
  align-items: center;

  font-size: 16px;
  font-weight: bold;
  padding: 6px 12px;
  border-radius: 4px;
  white-space: nowrap;
  background-color: #ffffff;
  gap: 8px;
  &:focus {
    outline: none;
  }
  &:hover {
    filter: brightness(0.9);
  }
}
.iconSpan {
  display: inline-flex;
  align-self: center;
  flex-shrink: 0;
}
.icon {
  width: 20px;
  height: 20px;
}
