.root {
  width: 300px;
  margin: 0 auto;
  margin-top: 50px;
  text-align: center;
  h1 {
    font-size: 1.7rem;
    font-weight: 600;
    margin-bottom: 30px;
  }
}
