@use "../../style/variables" as variable;
.TalentImgs {
  // background-color: #ffff00;
  border: 3px #2182c3 solid;
  border-radius: 16px;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;

  padding: 40px 30px;
  @include variable.mq(md) {
    padding: 60px 30px;
  }
  background-color: white;
}
.notes {
  max-width: 700px;
  margin: auto;
  text-align: center;
  color: #656565;
  font-size: 18px;
  font-weight: 400;
  padding-top: 40px;
  padding-bottom: 20px;
}
.bgTalent {
  text-align: center;
  font-size: 28px;
  font-weight: 900;
  padding: 24px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22395.45%22%20height%3D%2280.74%22%20viewBox%3D%220%200%20395.45%2080.74%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_1337%22%20data-name%3D%22%E3%83%91%E3%82%B9%201337%22%20d%3D%22M-163.46%2C0h-35.75V-9.46l9.57-4.4V-64.68l-9.57-4.4v-9.46h35.75v9.46l-9.57%2C4.4v50.82l9.57%2C4.4Zm57.64%2C0-18.92-61.6h-.44l.33%2C6.6q.22%2C4.4.44%2C9.46t.22%2C9.02V0h-14.85V-78.54h22.66l18.59%2C60.06h.33l19.69-60.06h22.66V0H-70.62V-37.18q0-3.74.165-8.58t.33-9.185q.165-4.345.275-6.545h-.44L-90.53%2C0ZM23.76%2C0%2C18.04-18.7h-28.6L-16.28%2C0H-34.21L-6.49-78.87H13.86L41.69%2C0ZM14.08-32.67%2C8.36-50.93q-.55-1.87-1.43-4.73T5.17-61.49q-.88-2.97-1.43-5.17-.55%2C2.2-1.485%2C5.445T.5-55.055Q-.33-52.14-.77-50.93L-6.38-32.67ZM90.2-44.11h31.13v40.7a89.353%2C89.353%2C0%2C0%2C1-12.87%2C3.3A85.348%2C85.348%2C0%2C0%2C1%2C93.39%2C1.1q-17.6%2C0-27.06-10.34T56.87-39.38q0-12.32%2C4.73-21.34A33%2C33%2C0%2C0%2C1%2C75.515-74.69Q84.7-79.64%2C97.9-79.64a57.627%2C57.627%2C0%2C0%2C1%2C12.32%2C1.32%2C56.922%2C56.922%2C0%2C0%2C1%2C11%2C3.52l-5.5%2C13.31a40.257%2C40.257%2C0%2C0%2C0-8.25-3.08%2C38.057%2C38.057%2C0%2C0%2C0-9.68-1.21%2C23.458%2C23.458%2C0%2C0%2C0-12.6%2C3.3%2C21.84%2C21.84%2C0%2C0%2C0-8.25%2C9.35A32.088%2C32.088%2C0%2C0%2C0%2C74.03-39.05a41%2C41%2C0%2C0%2C0%2C2.09%2C13.64%2C19.144%2C19.144%2C0%2C0%2C0%2C6.6%2C9.3q4.51%2C3.355%2C11.77%2C3.355a44.992%2C44.992%2C0%2C0%2C0%2C5.94-.33q2.42-.33%2C4.62-.77V-30.25H90.2ZM196.24%2C0H151.03V-78.54h45.21V-64.9h-28.6v17.27h26.62v13.64H167.64v20.24h28.6Z%22%20transform%3D%22translate(199.21%2079.64)%22%20fill%3D%22%232082c2%22%20opacity%3D%220.114%22%2F%3E%3C%2Fsvg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.imgs {
  padding-top: 40px;
  padding-bottom: 20px;
  margin-bottom: 40px;
  @include variable.mq(md) {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  grid-auto-rows: minmax(100px, auto);
}
.img {
  //aspect-ratio: 1/1;
  width: 100%;
  object-fit: cover;
}

.noImage {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 24px;
}
